<template>
  <vs-popup
    fullscreen
    :title="title"
    :active.sync="active"
  >
    <div class="vs-con-loading__container">
      <embed
        :src="url"
        alt="Documento PDF"
        width="100%"
        height="600"
        type="application/pdf"
        pluginspage="https://www.adobe.com/products/acrobat/readstep2.html"
      />

    </div>
  </vs-popup>
</template>

<script>
import axios from '@/axios'
export default {
  props: {
    title: {
      default () {
        return 'Documento PDF'
      }
    },
    format: {}
  },
  data () {
    return {
      active: false,
      url: ''
    }
  },
  methods: {
    get (url) {
      this.$vs.loading()
      axios.get(url, {
        responseType:'arraybuffer'
      })
        .then(this.showPdf)
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    post (param) {
      this.$vs.loading()
      axios.post(param.url, param.payload, {
        responseType:'arraybuffer'
      })
        .then(this.showPdf)
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    showPdf (response) {
      this.$emit('show')
      if (this.format === 'EXCEL') {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${this.title || 'Relatório'}.xlsx`
        link.click()
      } else {
        const blob = new Blob([response.data], { type:   'application/pdf' })
        this.url =  window.URL.createObjectURL(blob)
        this.active = true
      }
    }
  }
}
</script>

<style lang="scss">
.popup50 .vs-popup {
  width: 50% !important;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup90 .vs-popup {
  width: 90% !important;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.vue_select_drop_size_180 .vs__dropdown-menu {
  max-height: 180px;
}
</style>
