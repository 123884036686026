var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "vx-row" },
      [
        _c("select-user", {
          staticClass: "vx-col md:w-1/3",
          attrs: {
            multiple: "",
            "use-id": true,
            label: "Responsável",
            placeholder: "TODOS",
          },
          on: { input: _vm.updateValue },
          model: {
            value: _vm.users,
            callback: function ($$v) {
              _vm.users = $$v
            },
            expression: "users",
          },
        }),
        _c("select-module", {
          staticClass: "vx-col md:w-1/3",
          attrs: { multiple: "" },
          on: { input: _vm.updateValue },
          model: {
            value: _vm.modules,
            callback: function ($$v) {
              _vm.modules = $$v
            },
            expression: "modules",
          },
        }),
        _c("select-operation", {
          staticClass: "vx-col md:w-1/3",
          attrs: { multiple: "" },
          on: { input: _vm.updateValue },
          model: {
            value: _vm.operations,
            callback: function ($$v) {
              _vm.operations = $$v
            },
            expression: "operations",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }