<template>
  <div id="tab-employee">
    <popup-pdf ref="popupPdf" />
    <vx-card v-if="!showAudit" class="general-cash-filters mb-4">
      <vs-alert class="mt-2" color="primary" title="Orientações:" :active="!showAudit" icon-pack="feather">
        <p>Você não tem permissão para acessar a Auditoria.</p>
        <p>Peça para seu administrador alterar as configurações e liberar seu acesso caso necessário.</p>
      </vs-alert>
    </vx-card>

    <div v-else class="vx-card p-6">
      <filter-audit v-model="filterAuditModel" @input="filterAuditNow" />
      <vs-divider />
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-1/2 w-full sm:order-normal order-3 sm:mt-0 ml-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca..." />
        <vx-tooltip text="Relatório PDF" position="top">
                <svg
                  @click="report"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  class="svg-hover h-8 w-8 cursor-pointer"
                  aria-hidden="true"
                  focusable="false"
                  width="0.86em"
                  height="1em"
                  style="
                    -ms-transform: rotate(360deg);
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                  "
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 1536 1792"
                >
                  <path
                    d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z"
                  />
                </svg>
              </vx-tooltip>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="payload.limit"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'

// Cell Renderer
import CellRendererDateTime from './cell-renderer/CellRendererDateTime.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import FilterAudit  from '@/components/audit/FilterAudit'

import moduleAudit          from '@/store/audit/moduleAudit.js'
import PopupPdf from '@/components/report/pdf/PopupPdf'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererDateTime,
    FilterAudit,
    CellRendererActions,
    PopupPdf
  },
  data () {
    return {
      filterAuditModel: {},
      showAudit: true,

      popupNewRegistration: false,
      popupEditRegistration: false,
      popupServiceOrder: false,
      popupMaintenance: false,
      popupFuelSupply: false,

      searchQuery: '',

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        consultApi: true
      },

      // AgGrid
      localeText: null,
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        wrapText: true
      },
      columnDefs: [
        {
          headerName: 'Data',
          field: 'created_at',
          width: 250,
          floatingFilter:true,
          filter: 'agDateColumnFilter',
          filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['equals', 'inRange'],
            defaultOption: 'equals',
            debounceMs: 800,
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = cellValue
              if (dateAsString === null) return -1
              const dateParts = dateAsString.split('/')
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              )

              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0
              }

              if (cellDate < filterLocalDateAtMidnight) {
                return -1
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1
              }
            },
            browserDatePicker: true,
            minValidYear: 2000
          },
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Anexo',
          sortable: false,
          field: 'actions',
          width: 110,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            printReceipt: this.printReceipt, // usado para passar parametros para dentro o componente renderizado no ag grid
            chargeback: this.chargeback, // usado para passar parametros para dentro o componente renderizado no ag grid
            confirmDeleteTransaction: this.confirmDeleteTransaction, // usado para passar parametros para dentro o componente renderizado no ag grid
            editTransaction: this.editTransaction, // usado para passar parametros para dentro o componente renderizado no ag grid
            boleto: this.boleto, // usado para passar parametros para dentro o componente renderizado no ag grid
            printCarnet: this.printCarnet, // usado para passar parametros para dentro o componente renderizado no ag grid
            printPromissoria: this.printPromissoria // usado para passar parametros para dentro o componente renderizado no ag grid
          }
        },
        {
          headerName: 'Responsável',
          sortable: false,
          field: 'user.name',
          width: 190
        },
        // {
        //   headerName: 'Operação',
        //   field: 'operation',
        //   sortable: false,
        //   width: 100
        // },
        {
          headerName: 'Descrição',
          field: 'description',
          sortable: false,
          width: 1500
        }
        // {
        //   headerName: 'Ações',
        //   field: 'actions',
        //   width: 150,
        //   sortable: false,
        //   cellRendererFramework: 'CellRendererActions',
        //   cellRendererParams: {
        //     delete: this.confirmDelete // usado para passar parametros para dentro o componente renderizado no ag grid
        //   }
        // }
      ],
      components: {
        CellRendererDateTime,
        CellRendererActions
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    allData () {
      return this.$store.state.audit.auditsGrid
    }
  },
  methods: {
    report () {
      this.$refs.popupPdf.post({
        url:  `${process.env.VUE_APP_API_URL}/report/log/list`,
        payload: this.payload
      })
    },
    filterAuditNow () {
      this.updateSearchQuery(this.searchQuery)
    },
    getAttach (title) {
      this.$http.get(`${process.env.VUE_APP_API_URL}/logs/file`)
        .then(response => {
          const blob = new Blob([response.data], { type: 'text/plain' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${title || 'Relatório'}.txt`
          link.click()
        })
    },
    refreshGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.setDatasource(this.dataSource)
      // this.gridApi.refreshInfiniteCache() // REMOVI ESSE PQ QDO NÃO HÁ NENHUM REGISTRO NÃO ATUALIZA AO CADASTRAR O PRIMEIRO
    },
    refreshInfiniteCacheGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.refreshInfiniteCache()
    },

    getSearchResult (searchVal) {
      this.payload = {
        ...this.payload,
        searchQuery: searchVal,
        ...this.filterAuditModel
      }
      this.gridApi.setDatasource(this.dataSource)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch('audit/fetchGrid', this.payload)
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return data
    }
  },
  mounted () {
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('allData')
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)

    if (!moduleAudit.isRegistered) {
      this.$store.registerModule('audit', moduleAudit)
      moduleAudit.isRegistered = true
    }

    // SE NÃO FOR ADMIN NÃO ACESSA
    if (!this.user.roles.some(role => role.name === 'admin')) {
      this.showAudit = false
    }

  }
}
</script>

<style>

</style>
