<template>
  <div>
    <div class="vx-row">
      <select-user v-model="users" multiple :use-id="true" label="Responsável" class="vx-col md:w-1/3"
      placeholder="TODOS"
      @input="updateValue" />
      <select-module v-model="modules" multiple class="vx-col md:w-1/3" @input="updateValue" />
      <select-operation v-model="operations" multiple class="vx-col md:w-1/3" @input="updateValue" />
    </div>
  </div>
</template>

<script>
import SelectUser from '@/components/register/user/SelectUser'
import SelectModule from '@/components/general/SelectModule'
import SelectOperation from './SelectOperation'

export default {
  components: {
    SelectUser,
    SelectModule,
    SelectOperation
  },
  props: {
    value: {}
  },
  data () {
    return {
      users: [],
      modules: [],
      operations: []
    }
  },
  methods: {
    updateValue () {
      this.$emit('input', {
        users: this.users,
        modules: this.modules,
        operations: this.operations
      })
    }
  }
}
</script>

<style>

</style>
