<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon
      v-if="attach"
      title="Download" icon="DownloadIcon" svgClasses="h-5 w-5 ml-2 hover:text-primary cursor-pointer"
       @click="download" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  computed: {
    attach () {
      return this.params.data ? this.params.data.file : false
    }
  },
  methods: {
    download () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/logs/file/${this.params.data.id}`, {
        responseType: 'blob'
      })
        .then(response => {
          const blob = new Blob([response.data], {  type: 'application/xml' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'auditoria.xlsx'
          link.click()
        })
    }
  }
}
</script>
