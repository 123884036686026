import axios from '@/axios.js'

export default {
  fetchGrid ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/logs`, payload)
        .then((response) => {
          commit('SET_AUDITS_GRID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
