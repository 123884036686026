var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { direction: _vm.$vs.rtl ? "rtl" : "ltr" } },
    [
      _vm.attach
        ? _c("feather-icon", {
            attrs: {
              title: "Download",
              icon: "DownloadIcon",
              svgClasses: "h-5 w-5 ml-2 hover:text-primary cursor-pointer",
            },
            on: { click: _vm.download },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }