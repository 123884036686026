import state from './moduleAuditState.js'
import mutations from './moduleAuditMutations.js'
import actions from './moduleAuditActions.js'
import getters from './moduleAuditGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
