var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "tab-employee" } },
    [
      _c("popup-pdf", { ref: "popupPdf" }),
      !_vm.showAudit
        ? _c(
            "vx-card",
            { staticClass: "general-cash-filters mb-4" },
            [
              _c(
                "vs-alert",
                {
                  staticClass: "mt-2",
                  attrs: {
                    color: "primary",
                    title: "Orientações:",
                    active: !_vm.showAudit,
                    "icon-pack": "feather",
                  },
                },
                [
                  _c("p", [
                    _vm._v("Você não tem permissão para acessar a Auditoria."),
                  ]),
                  _c("p", [
                    _vm._v(
                      "Peça para seu administrador alterar as configurações e liberar seu acesso caso necessário."
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "vx-card p-6" },
            [
              _c("filter-audit", {
                on: { input: _vm.filterAuditNow },
                model: {
                  value: _vm.filterAuditModel,
                  callback: function ($$v) {
                    _vm.filterAuditModel = $$v
                  },
                  expression: "filterAuditModel",
                },
              }),
              _c("vs-divider"),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "flex-grow" },
                    [
                      _c(
                        "vs-dropdown",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { "vs-trigger-click": "" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentPage * _vm.paginationPageSize -
                                      (_vm.paginationPageSize - 1)
                                  ) +
                                    " - " +
                                    _vm._s(
                                      _vm.allData.total -
                                        _vm.currentPage *
                                          _vm.paginationPageSize >
                                        0
                                        ? _vm.currentPage *
                                            _vm.paginationPageSize
                                        : _vm.allData.total
                                    ) +
                                    " de " +
                                    _vm._s(_vm.allData.total)
                                ),
                              ]),
                              _c("feather-icon", {
                                attrs: {
                                  icon: "ChevronDownIcon",
                                  svgClasses: "h-4 w-4",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "vs-dropdown-menu",
                            [
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.paginationSetPageSize(10)
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("10")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.paginationSetPageSize(20)
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("20")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.paginationSetPageSize(30)
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("30")])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("vs-input", {
                    staticClass:
                      "sm:mr-4 mr-0 sm:w-1/2 w-full sm:order-normal order-3 sm:mt-0 ml-0 mt-4",
                    attrs: { placeholder: "Busca..." },
                    on: { input: _vm.updateSearchQuery },
                    model: {
                      value: _vm.searchQuery,
                      callback: function ($$v) {
                        _vm.searchQuery = $$v
                      },
                      expression: "searchQuery",
                    },
                  }),
                  _c(
                    "vx-tooltip",
                    { attrs: { text: "Relatório PDF", position: "top" } },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "svg-hover h-8 w-8 cursor-pointer",
                          staticStyle: {
                            "-ms-transform": "rotate(360deg)",
                            "-webkit-transform": "rotate(360deg)",
                            transform: "rotate(360deg)",
                          },
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            "xmlns:xlink": "http://www.w3.org/1999/xlink",
                            "aria-hidden": "true",
                            focusable: "false",
                            width: "0.86em",
                            height: "1em",
                            preserveAspectRatio: "xMidYMid meet",
                            viewBox: "0 0 1536 1792",
                          },
                          on: { click: _vm.report },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("ag-grid-vue", {
                ref: "agGridTable",
                staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                attrs: {
                  components: _vm.components,
                  gridOptions: _vm.gridOptions,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                  overlayNoRowsTemplate: _vm.noRowsTemplate,
                  localeText: _vm.localeText,
                  rowSelection: "multiple",
                  colResizeDefault: "shift",
                  animateRows: true,
                  pagination: true,
                  paginationPageSize: _vm.paginationPageSize,
                  cacheBlockSize: _vm.payload.limit,
                  suppressPaginationPanel: true,
                  enableRtl: _vm.$vs.rtl,
                },
                on: { "grid-ready": _vm.onGridReady },
              }),
              _c("vs-pagination", {
                attrs: { total: _vm.totalPages, max: 10 },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }