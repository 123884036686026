var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      attrs: { fullscreen: "", title: _vm.title, active: _vm.active },
      on: {
        "update:active": function ($event) {
          _vm.active = $event
        },
      },
    },
    [
      _c("div", { staticClass: "vs-con-loading__container" }, [
        _c("embed", {
          attrs: {
            src: _vm.url,
            alt: "Documento PDF",
            width: "100%",
            height: "600",
            type: "application/pdf",
            pluginspage:
              "https://www.adobe.com/products/acrobat/readstep2.html",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }